import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Pie } from "react-chartjs-2";
import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Tooltip,
} from "chart.js";
import "../styles/pages/PropertyBreakdown.css";

// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale);

const PropertyBreakdownV2 = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [property, setProperty] = useState(null);

  useEffect(() => {
    if (!location.state?.property) {
      navigate("/");
      return;
    }
    setProperty(location.state.property);
  }, [location, navigate]);

  const generateChartData = () => {
    if (!property || !property.profiles || property.profiles.length === 0)
      return null;

    // Count unique values
    const valueCount = property.profiles.reduce((acc, profile) => {
      acc[profile.value] = profile.count || 0;
      return acc;
    }, {});

    // Generate colors for each unique value
    const colors = Object.keys(valueCount).map((_, index) => {
      const hue = (index * 137.5) % 360; // Golden angle approximation
      return `hsl(${hue}, 70%, 60%)`;
    });

    return {
      labels: Object.keys(valueCount),
      datasets: [
        {
          data: Object.values(valueCount),
          backgroundColor: colors,
          borderColor: colors.map((color) => color.replace("60%", "50%")),
          borderWidth: 1,
        },
      ],
    };
  };

  const generateValueStats = () => {
    if (!property || !property.profiles || property.profiles.length === 0)
      return {};

    // Count unique values
    return property.profiles.reduce((acc, profile) => {
      acc[profile.value] = profile.count || 0;
      return acc;
    }, {});
  };

  const generateColor = (index) => {
    const hue = (index * 137.5) % 360; // Golden angle approximation
    return `hsl(${hue}, 70%, 60%)`;
  };

  const chartOptions = {
    responsive: true,
    // maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          boxWidth: 15,
          padding: 15,
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const value = context.raw;
            const total = context.dataset.data.reduce((a, b) => a + b, 0);
            const percentage = ((value / total) * 100).toFixed(1);
            return `${context.label}: ${value} (${percentage}%)`;
          },
        },
      },
    },
  };

  if (!property) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="property-breakdown">
      <div className="header">
        <button className="back-button" onClick={() => navigate("/home")}>
          ← Back to Properties
        </button>
      </div>

      <h1 className="property-title">{property.name}</h1>

      <div className="content-grid">
        <div className="values-section">
          <h3>Values</h3>
          <div className="values-table">
            <table>
              <thead>
                <tr>
                  <th>Value</th>
                  <th>Total</th>
                  <th>Key</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(generateValueStats()).map(
                  ([value, count], index) => (
                    <tr key={value}>
                      <td>{value}</td>
                      <td>{count}</td>
                      <td>
                        <div
                          className="color-key"
                          style={{ backgroundColor: generateColor(index) }}
                        />
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="chart-section">
          <div className="chart-container">
            {generateChartData() && (
              <Pie
                data={generateChartData()}
                options={chartOptions}
              />
            )}
          </div>
        </div>
      </div>

      <div className="instructions-section">
        <h3>To View this Segment in Klaviyo:</h3>
        <ol>
          <li>Log into your Klaviyo.</li>
          <li>
            On the sidebar, navigate to{" "}
            <span className="path">Lists/Segments > Create New Segment</span>
          </li>
          <li>Name this Segment [ Title ] [Value]</li>
          <li>
            In the next section 'Select a Condition' click the dropdown menu and{" "}
            <strong>Properties</strong> about someone.
          </li>
          <li>
            In the options that follow:
            <ul>
              <li>
                In the first column select <strong>{property.name}</strong>
              </li>
              <li>
                In the second Column, select whether you want the value to be
                equals, contain etc.
              </li>
              <li>
                In the third Column, chose the values you wish to include in the
                segment.
              </li>
              <li>In the fourth column, Type: Text.</li>
            </ul>
          </li>
        </ol>
        <p className="note">
          <strong>NB:</strong> you can add multiple rules to the segment by
          clicking Add condition and repeating this process.
        </p>
      </div>
    </div>
  );
};

export default PropertyBreakdownV2;
